/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import { useSiteMetadata } from "../../../../../theme/src/hooks";

export type FooterBodyProps = {
  // empty
};

export const FooterBody: React.FC<FooterBodyProps> = () => {
  const { siteTitle } = useSiteMetadata();

  return (
    <div>
      <p>
        &copy; {new Date().getFullYear()} {siteTitle}. All rights reserved.
      </p>
    </div>
  );
};
export default FooterBody;
