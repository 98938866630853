

import { HeaderMenuItem } from "../components/header-menu";

export const headerMenuItems: HeaderMenuItem[] = [
  {
    label: "Home",
    slug: "/",
  },
  /*
  {
    label: "Blog",
    slug: "/blog",
    isVisibleTablet: false,
  },

  {
    label: "Tags",
    slug: "/tags",
  },
   */
  {
    label: "About",
    slug: "/about",
  },
  {
    label: "Rarible",
    href: "https://rarible.com/almogk",
  },
  {
    label: "Twitter",
    href: "https://twitter.com/isalmog",
  },
];
