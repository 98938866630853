/** @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import {
  Post,
  Slide,
  SlideBlog,
  SlideTitle,
  SlideDescription,
  FooterLogo,
  SlideButton,
} from "@shetharp/gatsby-theme-polaroid";
import { useStaticQuery, graphql, Link } from "gatsby";

export type SlidesProps = {
  posts: Post[];
};

/**
 * Shadow this file to add your own Slides to the homepage.
 * You can also split individual slides into their own file and import them here.
 */
const Slides: React.FC<SlidesProps> = (props) => {
  const { posts } = props;

  /**
   * Query for images
   * By default, these images should be located in your `/content/images` directory
   * The configuration for slideImageFragment is in `/src/data/slide-image-fragment.ts`
   */
  const slideImages = useStaticQuery(graphql`
    query {
      imgIntro: file(relativePath: { eq: "chile-peppers.jpg" }) {
        ...slideImageFragment
      }
      imgLife: file(relativePath: { eq: "The-Good-Life-AlmogK.jpeg" }) {
        ...slideImageFragment
      }
      imgGreen: file(relativePath: { eq: "Into-The-Green.JPG" }) {
        ...slideImageFragment
      }
    }
  `);

  return (
    <React.Fragment>
      {/**
       * INTRO
       */}
      <Slide
        id="intro"
        title="Hi, I'm Almog Koren a photography-focused digital artist working with NFT's"
        description={
          <React.Fragment>
            <p>
            By day I'm a software engineer focusing on Blockchain and Google Flutter development, and by night I'm a digital artist. I focus on animation and photography. My style is what I call a "unique perspective." I'm interested in movement and motion using animation or artificial intelligence (coding). I create feeling and storytelling thru movement using photography-focused digital artwork.
            </p>
          </React.Fragment>
        }
        fluid={slideImages.imgIntro.childImageSharp.fluid}
        overlayColor="veronica.dark"
        highlightColor="primary"
        isColorful
        isBorderless
        hasScrollIndicator
      />

      {/**
       * PREVIEW
       */}
      <Slide
        id="life"
        title="The Good Life"
        description={
          <React.Fragment>
            <p>
              Taken at Bondi Beach, Syndey "The Good Life" is about finding the calm in life. I love how the subjects are siting and looking out{" "}
              while <em>the revealing motion happens showing the rocks</em>.
            </p>
          </React.Fragment>
        }
        fluid={slideImages.imgLife.childImageSharp.fluid}
        overlayColor="primary"
        highlightColor="accent"
        isExpanded
        imagePosition="0% 0%"
        button={{ text: "view on rarible",  href: "https://rarible.com/token/0x60f80121c31a0d46b5279700f9df786054aa5ee5:544233:0xe7d8b190961c6761907bc7154e26cc97e2e01a5e" }}
      />

      {/**
       * WORK
       */}
      <Slide
        id="green"
        title="In To The Green"
        description={
          <React.Fragment>
            <p>Walking into a green banna tunnel what do you feel?
            </p>
          </React.Fragment>
        }
        fluid={slideImages.imgGreen.childImageSharp.fluid}
        overlayColor="eucalyptus.dark"
        highlightColor="lime.light"
        isExpanded
        imagePosition="0% 0%"
        button={{ text: "coming soon", href: "#" }}
      />



      {/**
       * BLOG
       */}
      {/**
      <SlideBlog id="blog" posts={posts} backgroundColor="mediumblue" overlayColor="primary">
        <SlideTitle>More</SlideTitle>
        <SlideDescription>
          <p>
            coming soon
          </p>
        </SlideDescription>
        <p>
          <SlideButton
            href=""
            sx={{ "&&&": { width: ["100%", null, "75%", "66.667%", "75%", null, "66.667%", null, "50%"] } }}
          >
            coming soon
          </SlideButton>
        </p>
        <SlideDescription>
          <p>
            <br />
            &mdash;
          </p>
          <p>
            <FooterLogo />
            <br />
            Almog Koren
            <br />
            By <a href="https://twitter.com/IsAlmog">Almog Koren</a>
          </p>
        </SlideDescription>
      </SlideBlog>
       */}
    </React.Fragment>
  );
};
export default Slides;
