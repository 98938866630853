import { HeaderNavItem } from "../components/header-nav";

export const headerNavItems: HeaderNavItem[] = [
  /*
  {
    label: "Blog",
    slug: "/blog",
    isVisibleMobile: true,
    isVisibleTablet: true,
  },
  */
  {
    label: "About",
    slug: "/about",
  },
  {
    label: "Rarible",
    href: "https://rarible.com/almogk",
  },
  {
    label: "Twitter",
    href: "https://twitter.com/isalmog",
  },
];
